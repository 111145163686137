$breakpoints: (
  phone: 428px,
  tablet: 768px,
  desktop: 1024px
);

@include media(">=desktop") {
  :root {
    --navbar-height: 184px;
  }
}
@include media("<=desktop") {
  :root {
    --navbar-height: 120px;
  }
}
