.hentedag--root {
  .hentedag--content {
    section {
      position: relative;
      min-height: 80px;

      .hentedag--dunk-container {
        position: absolute;
        right: 0;
        top: 0;
        @media (max-width: 420px) {
          margin-top: 5px;
          position: static;
        }

        img {
          height: 80px;
        }
      }
    }
  }
}
