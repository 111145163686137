@import "../style/theme";

.page-view--page {
  margin: 20px;
  @include media("<=phone") {
    margin: 0;
  }
  .page-view--content {
  }
  .page-view--cms {
    background: $white;
    border: 1px solid $border;
    border-radius: 0 0 12px 12px;
    padding: 40px;
    min-height: 35vh;
    overflow-wrap: break-word;

    @include media("<=phone") {
      padding: 10px;
    }

    img {
      max-width: 100%;
      margin: 0 15px 15px 0;
      padding: 0 !important;
      border-radius: 3px;
    }

    img.full {
      width: 100%;
      margin: 15px 0;
    }

    iframe {
      margin: 10px 0;
    }

    table {
      width: 100%;
    }

    details {
      padding: 0 15px;
      margin: 10px 0;
      border: none;

      &[open] {
        padding-bottom: 15px;
      }

      * {
        animation: sweep 0.3s ease;
      }

      summary {
        background: $newDarkGreen;
        color: white;
        cursor: pointer;
        padding: 15px;
        border: none;
        border-radius: 3px;
        text-align: left;
        outline: none;
        font-size: 20px;
        margin: 0 -15px 15px;
        line-height: 1.5;
        font-size: 16px;
        font-weight: 400;

        &:focus,
        &:hover {
          outline-style: none;
          background: $newDarkGreen;
        }
      }
    }

    @keyframes sweep {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  .loader {
    height: 60vh;
    text-align: center;
    .loading--dots {
      @include absolute(50%);
      transform: translateX(-50%);
      span {
        background: $lightBlue;
      }
    }
  }
}
