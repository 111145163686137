@import "../style/theme";

.map-page-view--page {
  padding: 40px;
  line-height: 28px;

  p,
  h4 {
    margin: 0;
  }

  details {
    padding: 0 15px;
    margin: 10px 0;
    border: none;

    &.collapsible--root {
      background-color: $newLightGreen;
    }

    &[open] {
      padding-bottom: 15px;
    }

    * {
      animation: sweep 0.3s ease;
    }

    summary {
      background: $newDarkGreen;
      color: white;
      cursor: pointer;
      padding: 15px;
      border: none;
      border-radius: 3px;
      text-align: left;
      outline: none;
      font-size: 20px;
      margin: 0 -15px 15px;
      line-height: 1.5;
      font-size: 16px;
      font-weight: 400;

      &:focus,
      &:hover {
        outline-style: none;
        background: $newDarkGreen;
      }
    }
  }

  @keyframes sweep {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 30px;

    .info--general {
      .hours-all--item {
        text-align: left;
        margin: 10px 0;

        h4 {
          margin: 0;
        }

        p {
          margin: 0;
          :nth-child(1) {
            width: 80px;
            display: inline-block;
          }
        }

        .holidays {
        }
      }
    }

    .info--garbage {
      .grid-container {
        display: inline-grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
      }
    }
  }
}
