// ----------------------------
// Size mixin
// ----------------------------
@mixin size($width: $width, $height: $height) {
  height: $height;
  width: $width;
}
@mixin square($size) {
  @include size($size, $size);
}
// ----------------------------
// Pseudo mixin
// ----------------------------
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}
// ----------------------------
// positions mixin
// ----------------------------
@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin absolute($args...) {
  @include position(absolute, $args...);
}
@mixin relative($args...) {
  @include position(relative, $args...);
}
@mixin fixed($args...) {
  @include position(fixed, $args...);
}

// ----------------------------
// Button mixin
// ----------------------------
@mixin btn(
  $weight: bold,
  $size: 12px,
  $bgColor: $newDarkGreen,
  $txtColor: $white
) {
  @include btn-color($bgColor: $bgColor, $txtColor: $txtColor);
  font-weight: $weight;
  font-size: $size;
  position: relative;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  padding: 12px;
  border: 0 solid transparent;
  border-radius: 10px;
  // text-transform: uppercase;
  cursor: pointer;
}
@mixin btn-color($bgColor: $white, $txtColor: $darkBlue) {
  background: $bgColor;
  color: $txtColor;
  a {
    color: $txtColor;
  }
}

@mixin link() {
  font-weight: bold;
  position: relative;
  background-color: transparent;
  transition: 0.2s background-color;
//   &:after {
//     content: "";
//     @include absolute($right: 0, $bottom: 0px, $left: 0);
//     border-bottom: 3px solid rgba($lightBlue, 0.3);
//   }

  &:hover {
    text-decoration: none;
    background-color: rgba($lightBlue, 0.3);
  }
}
