@import "../../style/theme";

.andre-tjenester--content {
  .content--flexWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .andre-tjenester-view--flex-item {
      font-weight: bold;
      border: 1px solid rgba($greyBlue, 0.4);
      border-radius: 2px;
      text-align: center;
      margin: 10px;
      flex: 1 0 calc(50% - 20px);
      &:last-child {
        flex: 0 0 calc(50% - 20px);
        align-self: flex-start;
        @media (max-width: 768px) {
          align-self: auto;
        }
      }

      img {
        position: relative;
        margin: 5px auto;
        height: 38px;
        width: 38px;
        display: block;
      }
      a {
        display: block;
        padding: 5px;
        text-decoration: none;
        position: static;

        &:after {
          display: none;
        }

        &:hover {
          background: transparent;
        }
      }
    }
  }
}
