@import "../../style/theme";

.news-single-view {
  .news-single-view--title {
    font-family: $font-family-bold;
    line-height: 44px;
    text-align: center;
  }
  .news-single-view--content {
    background: $white;
    border: 1px solid $border;
    border-radius: 0 0 12px 12px;
    padding: 40px;

    .news-single-view--categories-bar {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .news-single-view--carousel {
      position: relative;
      text-align: center;
      margin-bottom: 40px;
      img {
        max-width: 100%;
      }
    }

    .news-single-view--article {
      div {
        p,
        li {
          font-family: $font-family-book;
          line-height: 28px;
        }

        h2,
        h3,
        h4 {
          font-family: $font-family-bold;
          line-height: 28px;
        }
        h2 {
          font-size: 32px;
        }

        h3 {
          font-size: 24px;
        }

        img {
          max-width: 100%;
        }

        iframe {
          max-width: 100%;
        }
      }
    }
  }
}
