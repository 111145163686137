@import "../../style/theme";

footer {
  max-width: 1360px;
  overflow: hidden;

  p,
  a {
    font-size: 18px;
    opacity: 1;
    color: $text-primary;
    font-weight: bold;

    &:not(p, .tlf) {
      text-decoration: underline;
    }
  }

  img {
    object-fit: fill;
    display: block;
    height: 100%;
    width: 100%;

    @include media("<=phone") {
      display: none;
    }
  }

  .footer--content {
    // @include absolute(null, 0, 10px, 0);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    background: $white 0% 0% no-repeat padding-box;
    padding: 20px 75px 20px 30px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.65);

    @include media("<=desktop") {
      flex-direction: column;
      align-items: center;
      padding: 20px 30px 20px 30px;
    }

    section {
      &.links {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        @include media("<=desktop") {
            flex-wrap: wrap;
          }

        a.footer--link {
          margin-right: 35px;
          line-height: 32px;
          &:last-of-type {
            margin-right: auto;
          }
        }
      }

      &.info {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        padding-top: 20px;
        align-items: center;
        justify-content: center;

        p {
          margin: 0;
        }

        @include media("<=desktop") {
          align-items: center;
          margin-left: 0;
        }

        @include media("<=phone") {
          margin-top: 30px;
          p {
            display: flex;
            flex-direction: column;
            align-items: center;
            &:not(:last-of-type) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  //   @include media("<=phone") {
  //     margin-top: 400px;
  //   }
}
