@import "../style/theme";

.naering-layout--root {
  @include media("<=phone") {
    h1 {
      font-size: 32px;
    }

    h2 {
      font-size: 28px;
    }

    h3 {
      font-size: 24px;
    }

    p,
    li,
    a {
      font-size: 18px;
    }
  }

  .hours--root {
    background: $greyBlue;
  }

  .footer--root {
    background: $greyBlue;
    .footer--content .double .wrapper a h4 {
      color: $white;
    }
  }

  .page-view--page > .page-view--cms {
    .collapsible--root > summary {
      background: $greyBlue;
    }
  }
}
