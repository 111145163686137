@import "../style/theme";

.skjemaView--wrapper {
  .dt-button {
    font-size: 16px;
    margin-right: 20px;
  }

  .dt-button:disabled {
    background-color: $newLightGreen;
    cursor: default;
  }

  .loader {
    @include absolute(0, 0, 0, 0);
    background: $overlay;
    z-index: 100;
    .loading--dots {
      @include absolute($top: 50%, $left: 50%);
      span {
        background: $green;
      }
    }
  }

  .message--button {
    margin-top: 20px;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    background: $green;
    color: $white;
    max-width: 250px;
  }

  .skjema--success {
    margin-bottom: 50px;
    p {
      font-size: 16px;
    }
  }

  .modal--container {
    .message--button.failure {
      max-width: none;
    }
  }

  .modal--overlay.save-failure {
    .modal--container {
      box-shadow: 0 2px 2px 2px #eee;
      border-radius: 3px;

      p {
        font-size: 16px;
      }
    }
  }
}
