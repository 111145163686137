@import "../style/theme";

.map-layout--root {
  @include media("<=phone") {
    h1 {
      font-size: 32px;
    }

    h2 {
      font-size: 28px;
    }

    h3 {
      font-size: 24px;
    }

    p,
    li,
    a {
      font-size: 18px;
    }
  }

  .map-layout--content {
    @include absolute(var(--navbar-height), 0, null, 50%);
    @include linear-gradient($white, #8fc3a7);
    box-shadow: $contentShadow;

    .footer--root > .footer--content {
      flex-direction: column;
      //   &:nth-child(1) {
      //     display: none;
      //   }
      //   &:nth-child(3) {
      //     display: none;
      //   }
    }

    @media (max-width: 1024px) {
      top: 60%;
      left: 5px;
      right: 5px;
      min-height: 40vh;
    }
  }
}
