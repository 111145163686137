@import "../../style/theme";

.newsAll {
  margin: 50px auto 150px auto;

  &--category-row {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 15px;

      .remove-filter-text {
          margin-left: 10px;

          a {
          text-decoration: underline;
          color: #000;
          font-weight: normal;
        }
      }
  }
  &--title {
    text-align: center;
    line-height: 44px;
    color: $text-primary;
  }
  &--content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    row-gap: 50px;
    justify-items: center;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
  }
  &--link {
    display: block;
    text-align: right;
    font-size: 23px;
    line-height: 44px;
    margin-top: 30px;
  }
}
