@import "../../style/theme";

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}

.loading--overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: $overlay;
}

.loading--dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    animation: pulse 1.5s linear infinite;
    border-radius: 10px;
    background-color: $darkGreen;
    margin-right: 6px;
  }

  span:nth-child(2) {
    animation-delay: 100ms;
  }
  span:nth-child(3) {
    animation-delay: 200ms;
  }
  span:nth-child(4) {
    animation-delay: 300ms;
  }
}
