@import "../style/theme";

.modal-open {
  overflow: hidden;
}

.modal--overlay {
  @include fixed(0, 0, 0, 0);
  background: $overlay;
  z-index: 100;

  .modal--container {
    @include absolute(50%, $left: 50%);
    transform: translate(-50%, -50%);
    background: $white;
    box-shadow: 0 4px 8px $shadow;
    width: 80%;
    max-width: 400px;
    min-height: 150px;
    padding: 20px;

    .modal--button-bar {
      position: absolute;
      right: 0;
      padding: 10px;
      height: 58px;
      width: 50%;
      top: 0;
      z-index: 100;

      .close-btn {
        @include absolute($right: 20px);
        @include square(32px);
        margin-top: 5px;
        border: transparent;
        border-radius: 50%;
        background-color: $white;
        z-index: 100;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      }
    }

    .modal--content {
      @include relative();
      @include square(100%);
    }
  }
}
