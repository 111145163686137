@import "../style/theme";

html .react-openlayers--overlay .collapsible--link {
  position: relative;
  display: block;
  cursor: pointer;
  margin: 10px 0;
  padding: 15px 15px 15px 35px;
  border: none;
  border-radius: 3px;
  text-align: left;
  outline: none;
  line-height: 1.5;
  font-size: 16px;
  color: white;
  font-weight: 400;
  cursor: pointer;
  background: $newDarkGreen;

  &::before {
    content: "";
    @include absolute(50%, null, null, 15px);
    transform: translateY(-50%);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid $white;
  }
  &:hover {
    background: $newDarkGreen;
    text-decoration: none;
  }
}

.collapsible--root {
  margin: 10px 0;
  border-bottom: 1px solid #ccc;
  background: $white;
  color: $darkBlue;
  border-radius: 3px;
  position: relative;
  width: 100%;
  overflow: hidden;

  .collapsible--header {
    font-weight: 400;
    display: inline-block;
    width: 100%;
    padding: 15px 50px 15px 10px;
    color: $white;
    position: relative;
  }
  .header--nonMobile {
    font-size: 16px;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      display: block;
    }
    @media (max-width: 768px) {
      display: block;
    }
  }
  .icon {
    @include square(26px);
    position: absolute;
    right: 10px;
    fill: $white;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 480px) {
      @include square(22px);
    }
  }

  .collapsible--content {
    padding: 10px;
    display: none;
    background: $white;
  }

  .collapsible--content.open {
    display: block;
  }
}

.collapsible--root.hushald {
  border: 1px solid $border;
  &.hushald-loading {
    pointer-events: none;  
  }

  .collapsible--header {
    background: $newDarkGreen;
  }
}
.collapsible--root.hytte {
  border: 1px solid $border;
  .collapsible--header {
    background: $newDarkGreen;
  }
}
.collapsible--root.naering {
  border: 1px solid $darkBlue;
  .collapsible--header {
    background: $darkBlue;
  }
}
