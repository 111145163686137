@import "../../style/theme";

.news {
  margin: 50px auto;

  &--title {
    text-align: center;
    // font-size: 42px;
    line-height: 44px;
    color: $text-primary;
  }
  &--content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 50px;
    justify-items: center;

    @include media("<=desktop") {
      grid-template-columns: 1fr 1fr;
    }

    @include media("<=tablet") {
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
  }
  &--link {
    display: block;
    text-align: right;
    font-size: 23px;
    line-height: 44px;
    margin-top: 30px;
  }
}
