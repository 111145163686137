@import "../style/theme";

.page-layout--root {
  @include media("<=phone") {
    h1 {
      font-size: 32px;
    }

    h2 {
      font-size: 28px;
    }

    h3 {
      font-size: 24px;
    }

    p,
    li,
    a {
      font-size: 18px;
    }
  }

  .page-layout--overlay {
    @include fixed(var(--navbar-height), 0, 0, 0);
    background: transparent linear-gradient(180deg, #d3e3d0 0%, #8fc3a7 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
  }

  .page-layout--container {
    @include relative(var(--navbar-height));
    margin: 30px auto 0 auto;
    height: auto;
    width: 100%;
    max-width: 1360px;

    @include media("<=desktop") {
      width: auto;
      margin: 20px 5px 0 5px;
    }

    .page-layout--content {
      @include media("<=tablet") {
        margin: 15px;
      }
      @include media("<=phone") {
        margin: 0;
      }
    }
  }

  .sortere-private-search {
    margin: 50px auto;
    max-width: 697px;
  }

  .footer--root {
    background: $darkGreen;
    position: relative;
  }
}
