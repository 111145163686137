@import "../../style/theme";

.menu {
  button {
    background: $btn-primary;
    color: $white;
    border: none;
    border-radius: 10px;
    padding: 0;
    font: inherit;
    outline: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .hamburger {
      display: inline-block;
      margin-right: 8px;
      span {
        @include size(27px, 3px);
        display: block;
        border-radius: 2px;
        margin: 0 auto;
        background: $white;
        opacity: 1;
        pointer-events: none;

        &:nth-child(3n-1) {
          margin: 3px auto;
        }
      }
      @include media("<=desktop") {
        margin-right: 0;
      }
    }

    .title {
      @include media("<=desktop") {
        display: none;
      }
    }
    @include media("<=tablet") {
      &.btn {
        background: transparent;
        color: $black;
        // background: transparent;
        margin-right: 0;

        .hamburger span {
          background: $newDarkGreen;
        }
        .title {
          display: none;
        }
      }
    }
  }
  nav {
    @include absolute(158, 0, null, 0);
    @include size(100%, 500px);
    box-shadow: $contentShadow;
    background: $menu-primary;
    max-width: 1360px;
    margin: 0 auto;
    padding: 75px 60px;
    border-radius: 12px;
    z-index: 300;

    .flex-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      height: auto;

      @include media("<=tablet") {
        flex-direction: column;
        align-items: center;
      }

      section {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        ul {
          padding: 0;
          margin: 0;
          height: 100%;
          &:not(:first-child) {
            margin-top: 20px;
          }
          li {
            a {
              font-family: $font-family-book;
              font-weight: normal;
            }
            list-style: none;
          }
        }

        &.maincategories {
          height: 100%;
          ul {
            display: flex;
            flex-direction: column;
            height: 100%;
            li {
              margin-bottom: 40px;

              &.button {
                background: $btn-warning;
                border-radius: 10px;
                padding: 25px;
                transform: translate(-25px);
                a {
                  color: $white;
                  font-family: $font-family-book;
                  text-decoration: none;

                  &:hover {
                    background-color: transparent;
                  }

                  &:after {
                    border-bottom: none;
                  }
                }
              }

              a {
                font-size: 32px;
                margin: 0;
                font-family: $font-family-bold;
              }
            }
          }
        }

        &.naering {
          p,
          a {
            font-size: 32px;
            line-height: 28px;
            margin: 0;
            font-family: $font-family-book;
          }
          p {
            font-family: $font-family-bold;
          }
          ul {
            margin: 0;
            li {
              margin-bottom: 40px;
            }
          }
        }
        &.navigation {
          ul {
            height: auto;

            li {
                margin-bottom: 3px;
            }
          }
          a {
            font-size: 23px;
          }
        }
      }
    }
  }
  @include media("<=tablet") {
    nav {
      height: auto;
      position: fixed;
      top: 70px;
      bottom: 0;
      overflow-y: auto;

      .flex-container {
        section {
          width: 100%;
          max-width: 350px;
        }
      }
    }
  }
}
