@import "../style/theme.scss";

.link-button {
  img {
    border-radius: 10px;
    max-width: 400px;
    max-height: 400px;
  }

  @include media("<=desktop") {
    img {
      max-width: 280px;
      max-height: 280px;
      object-fit: fill;
    }
  }
}
