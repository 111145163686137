@import "../style/theme";

.hushald-view--root {
  position: relative;
  padding: 20px;
  @include linear-gradient($background, #8fc3a7);

  .hushald-view--header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 40px;

    .hours {
      .btn {
        font-size: 20px;
        text-transform: uppercase;
      }
      .hours-all {
        @include absolute(72px, 0, null, 0);
      }
    }
  }

  .hushald-view--content {
    .hushald-view--selected-address {
      span {
        font-weight: 600;
      }

      a {
        color: $darkBlue;
        text-decoration: underline;
        font-weight: normal;
        font-size: 18px;

        &::after {
          display: none;
        }
        &:hover {
          background: transparent;
        }
      }
    }

    .hushald-view--children {
      margin-top: 30px;
    }
  }

  button.hushald-view--bookmark {
    padding: 5px;
    background: none;
    color: $darkBlue;
    border: none;
    box-shadow: none;
    float: right;
    text-decoration: underline;

    .bp3-icon {
      color: currentColor;
      opacity: 0.7;
    }

    &.is-bookmarked {
      color: $darkGreen;
      .bp3-icon {
        color: currentColor;
        opacity: 1;
      }
    }

    &:hover,
    &:active,
    &:focus {
      background: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
    }
  }

  .modal--overlay {
    position: absolute;
    .modal--container {
      width: 70%;
      min-height: 100px;
      .modal--content {
        text-align: center;
        .btn {
          background: $lightBlue;
          color: $white;
          // border: 1px solid rgba($lightBlue, 0.5);
        }
      }
    }
  }
}
