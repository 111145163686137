@import "../../style/theme";

.search {
  max-width: 990px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  h2 {
    text-align: center;
    margin: 0;
    &.search--title {
      font-family: $font-family-book;
      width: 100%;
    }

    &.search--subtitle {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .search-field {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;

    &--agents {
      overflow: visible;
      flex: 0 0 162px;
      position: static;
      max-width: 162px;
      box-shadow: none;

      .search-agent {
        position: fixed;
        border: 1px solid #eee;
        background: #fff;
        left: 0;
        right: 0;
        bottom: 0;
        margin-right: auto;
        margin-left: auto;
        top: calc(var(--navbar-height) + 195px);
        width: 100%;
        max-width: 990px;
        overflow-y: auto;

        @media (max-width: 768px) {
          top: calc(var(--navbar-height) + 230px);
        }
      }
    }
    // &--clear {
    //   right: 203px;
    // }
    &--field {
      //   position: relative;
      flex: 1 1 250px;
      &__options {
        // position: absolute;
        // right: 76px;
        // top: -45px;
        label {
          display: none;
        }
        select {
          padding: 10px;
          background: #aed6bd 0% 0% no-repeat padding-box;
          border: 1px solid #1f645b;
          font-family: $font-family-book;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
        }
        // select {
        //   padding: 10.5px;
        //   background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
        //     no-repeat 95% 50%;
        //   //   -moz-appearance: none;
        //   -webkit-appearance: none;
        //   //   appearance: none;
        //   /* and then whatever styles you want*/
        //   font-family: $font-family-book;
        //   height: 30px;
        //   width: 100px;
        //   // padding: 5px;
        // }
        button {
          width: 45px;
          height: 45.5px;
          background: #1b645b 0% 0% no-repeat padding-box;
          border: 1px solid #1b645b;
          border-radius: 0 10px 10px 0;
          position: absolute;
          bottom: 0;
          .icon {
            @include square(100%);
            svg {
              @include square(32px);
              fill: $white;
            }
          }
        }
      }
      & input {
        // width: 80%;
        background: #aed6bd 0% 0% no-repeat padding-box;
        border: 1px solid #1f645b;
        border-right: none;
        border-radius: 10px 0 0 10px;
      }
    }
  }
}
