@import "../../style/theme";

.nav-bar--root {
  @include size(100%, var(--navbar-height));
  @media (max-width: 768px) {
    @include size(100%, 120px);
  }
  @include fixed(0);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: $newDarkGreen;
  background: $background;
  z-index: 100;

  .nav-bar--root-container {
    @include square(100%);
    display: flex;
    max-width: 1360px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    @media (max-width: 768px) {
      align-items: flex-start;
      margin-top: 10px;
      flex-wrap: wrap;
    }
    // @include media("<=phone") {
    //   flex-wrap: wrap;
    // }

    .nav-bar--top-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .nav-bar--top-row-buttons {
      display: flex;
      flex: 1 0 auto;
      justify-content: flex-end;

      .btn {
        height: 50px;
        font-size: 20px;
        padding: 12px 40px;
        margin-left: 20px;
        margin-right: 20px;
        // color: $newDarkGreen;

        @include media("<=tablet") {
          padding: 0;
          margin-left: 0;
        }
        // &:last-child {
        //   margin-right: 50px;
        // }

        // &:not(&:last-child) {
        //   margin-right: 40px;
        // }

        &-secondary:hover {
          background-color: $btn-secondary;
        }
        &-warning {
          //   margin-left: auto;
          //   margin-right: 40px;

          &:hover {
            background: $btn-warning;
          }
        }

        &.active {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    .nav-bar--logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include media("<=phone") {
        width: 100%;
      }

      a.nav-bar--logo-link {
        display: block;
        color: $newDarkGreen;
        max-width: 200px;

        img.nav-bar--logo {
          fill: $newDarkGreen;
          @media (max-width: 768px) {
            max-width: 340px;
          }

          @media (max-width: 440px) {
            max-width: 150px;
          }
        }
      }
      .nav-bar--header {
        font-family: $font-family-book;
        font-size: 24px;
        margin-left: 50px;

        @include media("<=desktop") {
          display: none;
        }
      }
    }

    .nav-bar--button-group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 90%;
      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

      @include media("<=phone") {
        width: auto;
        display: block;
      }

      .nav-bar--button-group__links {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }

      .hours {
        margin: 0 40px;
      }

      //   .btn {
      //     height: 50px;
      //     font-size: 20px;
      //     padding: 12px 40px;

      //     // &:last-child {
      //     //   margin-right: 50px;
      //     // }

      //     // &:not(&:last-child) {
      //     //   margin-right: 40px;
      //     // }

      //     &-secondary:hover {
      //       background-color: $btn-secondary;
      //     }
      //     &-warning {
      //       margin-left: auto;
      //       margin-right: 40px;

      //       &:hover {
      //         background: $btn-warning;
      //       }
      //     }

      //     &.active {
      //       border-bottom-left-radius: 0;
      //       border-bottom-right-radius: 0;
      //     }
      //   }
    }
  }
  @include media("<=tablet") {
    .nav-bar--root-container {
      flex-direction: row;
      justify-content: space-around;
    }
  }
}
