@import "../../style/colors";

.skjemaView--wrapper {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  .dt-form {
    max-width: 600px;
  }
  .skjema-header {
    span {
      font-size: 12px;
    }
  }

  .attachments__drop-area {
    border: 2px dashed #fff;
  }

  .attachments__file {
    .icon svg {
      fill: $black;
    }
  }

  .link-block {
    display: block;
    margin: 0 0 24px 28px;
  }
}
