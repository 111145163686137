@import "./style/theme";

$zoom-controls-offset: calc(8px + var(--navbar-height));
$zoom-controls-height: 91px;
$location-controls-offset: calc(
  #{$zoom-controls-height} + #{$zoom-controls-offset}
);

html,
body {
  * {
    box-sizing: border-box;
  }

  .react-openlayers--map {
    @include fixed(var(--navbar-height), 0, 0, 0);
  }
  .zoom-control--root {
    @include fixed($zoom-controls-offset);
    background-color: transparent;
    button {
      @include square(44px);
      background: $newDarkGreen;
    }
  }
  .react-openlayers--show-location {
    @include fixed($location-controls-offset, null, null, 8px);
    background-color: transparent;
    @include square(44px);

    &:hover,
    :focus {
      background-color: transparent;
      outline: none;
      button {
        background: $newDarkGreen;
      }
    }
    button {
      @include square(44px !important);
      background: $newDarkGreen;
      &:focus {
        background: $newDarkGreen;
        outline: none;
      }
    }
  }
  .react-openlayers--overlay {
    .layer-switch--button-container {
      display: none;
    }

    a {
      color: $darkBlue;
    }
  }

  button.btn {
    border: none;
    border-radius: 10px;
    padding: 0;
    font: inherit;
    outline: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    @include btn();

    &.btn-secondary {
      @include btn-color($btn-secondary, $white);
    }
    &.btn-warning {
      @include btn-color($btn-warning, $white);
    }
  }

  a.btn {
    @include btn();

    &.btn-secondary {
      @include btn-color($btn-secondary, $white);
    }
    &.btn-warning {
      @include btn-color($btn-warning, $white);
    }
  }

  a:not([class]),
  a[class=""] {
    @include link();
  }
}
