@import "../../style/theme";

.card {
  width: 100%;
  max-width: 310px;
  min-width: 240px;

  &--thumbnail {
    display: block;
    width: 100%;
    height: 230px;
    object-fit: cover;
  }

  &--content {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid $border;
    border-radius: 0px 0px 12px 12px;
    padding: 15px;
    height: 230px;

    h3,
    p,
    b {
      line-height: 24px;
      font-size: 18px;
    }

    &__title {
      font-family: $font-family-bold;
    }

    &__description {
      font-family: $font-family-book;
    }
  }
}
