@import "../style/theme";

.naering-view--root {
  .second-navbar--root {
    background: $greyBlue;
  }
  .naering-view--container {
    padding: 10px 0 20px 0;
    .naering-view--flex-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .naering-view--intro-text {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        > div {
          max-width: 900px;
          padding: 10px 50px;
        }
      }

      .naering-view--flex-item {
        flex: 1 0 calc(50% - 60px);
        border: 1px solid $greyBlue;
        border-radius: 3px;
        padding: 10px;
        text-align: center;
        margin: 10px 30px;

        a {
          font-size: 16px;
          font-weight: 600;
          position: static;

          &:after {
            display: none;
          }
          &:hover {
            background: transparent;
          }
        }

        @media (max-width: 768px) {
          margin: 10px auto;
          flex: 1 0 calc(100% - 40px);
        }
        img {
          @include square(44px);
          position: relative;
          margin: 5px auto;
          display: block;
        }
      }
    }
  }
}
