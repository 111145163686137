@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
    to top: bottom,
    to top right: bottom left,
    to right top: left bottom,
    to right: left,
    to bottom right: top left,
    to right bottom: left top,
    to bottom: top,
    to bottom left: top right,
    to left bottom: right top,
    to left: right,
    to left top: right bottom,
    to top left: bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}

@function is-direction($value) {
  $is-keyword: index(
    (
      to top,
      to top right,
      to right top,
      to right,
      to bottom right,
      to right bottom,
      to bottom,
      to bottom left,
      to left bottom,
      to left,
      to left top,
      to top left
    ),
    $value
  );
  $is-angle: type-of($value) == "number" and
    index("deg" "grad" "turn" "rad", unit($value));

  @return $is-keyword or $is-angle;
}

@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction));
  background: linear-gradient($direction, $color-stops);
}

$green: #00a652;
$darkGreen: #008441;
$darkerGreen: #006733;
$lightBlue: #478bb4;
$blue: #2b6992;
$redOrange: #ed3900;
$orange: #ed8200;
$darkBlue: #204b68;
$darkGreyBlue: #253746;
$greyBlue: #415464;
$lightGreyBlue: #786693;
$yellow: #fcfc18;

$navLinkBackground: #bebebe;
$mapOverlay: rgba(255, 255, 255, 0.4);
$navbarShadow: 0 0 10px rgba(0, 0, 0, 0.2);
$contentShadow: 0 10px 10px rgba(0, 0, 0, 0.1);
$pageBackground: rgb(255, 255, 255);

$overlay: rgba(255, 255, 255, 0.5);
$shadow: rgba(0, 0, 0, 0.1);

$white: #fff;
$black: #222222;
$newLightGreen: #8fc3a7;
$newDarkGreen: #1b645b;
$newLinearGradientLight: #d3e3d0;

$background: #d3e3d0;
$primary: #1b645b;
$btn-primary: $newDarkGreen;
$btn-secondary: $greyBlue;
$btn-warning: #ac4900;
$menu-primary: #aed6bd;
$text-primary: $black;
$border: #4e7e67;
