@import "../../style/theme";

.attachments {
  max-width: 600px;
  margin: 30px 0;
  &__drop-area {
    position: relative;
    border: 2px dashed #bababa;
    border-radius: 21px;
    text-align: center;
    padding: 60px 10px;
    margin-top: 10px;
  }

  &__file {
    position: relative;
    margin-top: 20px;
  }

  position: relative;

  .btn {
    position: absolute;
    width: 42px;
    height: 42px;
    font-size: 14px;
    top: -10px;
    right: 10px;
    border-radius: 50%;
    animation: fadein 200ms ease-out;
    background: $white;
    border: 1px solid;
    cursor: pointer;

    .icon {
      position: initial;
      height: 38px;
      width: 38px;
    }
  }

  &__file__details {
    margin: 15px 0;
    padding: 10px 0;
    max-width: calc(100vw - 80px);
    animation: fadein 200ms ease-out;
    position: relative;
    display: flex;
    flex-wrap: no-wrap;
    padding: 10px 0;
    border-bottom: 2px solid $green;

    @media (max-width: 600px) {
      display: block;
    }

    & > * {
      vertical-align: middle;
    }

    & > .icon {
      font-size: 24px;
      color: $darkGreen;
    }

    .filename {
      display: inline-block;
      word-wrap: break-word;
      width: calc(100% - 210px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .thumbnail {
      position: relative;
      flex: 1 1 25%;
      padding: 40px 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        max-width: 100%;
        max-height: 100%;
        border-radius: 10px;
        overflow: hidden;
      }

      .icon {
        width: 100%;
        height: 100%;

        svg {
          max-height: 50px;
        }
      }
    }

    .bp3-form-group.dt-field {
      flex: 1 1 75%;
      padding-left: 10px;
    }
  }

  &__hint {
    position: absolute;
    top: 0;
    left: 0;
    background: lighten($darkGreen, 70%);
    width: 100%;
    height: 100%;
    z-index: 99;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $darkGreen;
    font-size: 24px;
    pointer-events: none;
    animation: fadein 200ms ease-out;

    .icon {
      height: 50px;
      width: 50px;

      svg {
        height: 100%;
        width: 100%;
        stroke: #333;
        fill: #333;
      }
    }
  }

  &__input {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;

    label {
      background: #fff;
      border: 1px solid #e2e2e2;
      border-radius: 17px;
      display: inline-flex;
      padding: 5px 20px;
      display: flex;
      align-items: center;

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      .icon {
        margin-right: 15px;
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    .icon {
      color: #cdcdd2;
      margin-right: 10px;
    }
    input {
      opacity: 0.01;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
  }
}
