@import "../../style/theme";

.varsel--content {
  .varsel--no-results {
    margin-top: 10px;
  }

  .varsel--flex-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .varsel--flex-item {
      border: 1px solid rgba($greyBlue, 0.4);
      border-radius: 2px;
      text-align: center;
      margin: 10px;
      padding: 10px;
      flex: 1 0 calc(100% - 20px);
      cursor: pointer;

      &.active {
        border: 4px solid #204b68;
      }

      .textmessage-meta {
        font-weight: bold;
        width: 100%;
        flex-direction: row;
        flex: 0 0 auto;
        justify-content: space-between;
        flex-wrap: nowrap;
        display: flex;

        .content-block {
          width: auto;
          font-size: 12px;
          text-transform: uppercase;
        }
      }

      .textmessage-content {
        text-align: left;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      &:first-child {
        margin-top: 20px;
      }
    }
  }
}
