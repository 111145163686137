@import "../../style/theme";

.sortere-private-search {
  text-align: center;
  #mini-sortere-private-search {
    #sortere-soke-privat-wrapper {
      #sortere-soke-privat-box {
        #sortere-soke-privat-form {
          #sortere-soke-privat-inline-title {
            display: none !important;
          }
          form {
            border: 1px solid #0faf7c !important;
            background-color: #fff;
            border-radius: 10px;

            #soke-privat-search-for {
              padding: 20px 40px 20px 25px !important;
              font-size: 16px !important;
              line-height: 19px !important;
            }

            svg {
              background: #0faf7c;
              border-radius: 0 7px 7px 0;
              padding: 0 16px !important;
              fill: #fff;
            }
          }
        }
      }
    }
  }

  #mini-sortere-search {
    form {
      border: 1px solid #0faf7c !important;
      background-color: #fff;
      border-radius: 10px;
      border-bottom: 1px solid #0faf7c !important;
      padding: 10px !important;
    }
  }
}
