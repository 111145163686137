@import "../../style/theme";

.hours {
  font-size: 12px;
  color: $black;

  .btn-link {
    @include link;
    font-family: $font-family-regular;
    font-size: 20px;
    line-height: 28px;
    color: $darkBlue;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  // .btn {
  //   background: #1b645b;

  //   &.active {
  //     border-bottom-left-radius: 0;
  //     border-bottom-right-radius: 0;
  //   }
  // }

  &-all {
    // @include absolute(158px, 0, null, 0);
    // margin-left: auto;
    // margin-right: auto;
    position: fixed;
    margin: 0 auto;
    top: var(--navbar-height);
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    padding: 40px 60px;
    background: $newLightGreen;
    box-shadow: $contentShadow;
    border-radius: 0 0 12px 12px;
    color: black;
    width: 100%;
    max-width: 1360px;
    z-index: 99;
    @include media("<=phone") {
      padding: 20px 30px;
    }

    p {
      font-family: $font-family-book;
      font-size: 20px;
      line-height: 44px;
    }

    &__container {
      display: flex;
      flex-flow: row wrap;
      justify-content: left;
      // negative margins to compesate for item margins
      margin-left: -10px;
      margin-right: -10px;

      &--item {
        text-align: left;
        padding: 15px;
        margin: 10px;
        flex: 1 1 250px;
        background: $newLinearGradientLight;
        border-radius: 2px;

        &.gjenvinningsstasjon {
          background: #aed6bd;
        }
        &.kundeservice {
          background: $newLinearGradientLight;
        }
        &.naering {
          background: #415464;
          color: $white;
        }
        // &:last-child {
        //   @media (max-width: 929px) {
        //     max-width: calc(50% - 20px);
        //   }
        //   @media (max-width: 686px) {
        //     max-width: 100%;
        //   }
        //   // max-width: calc(33.33333% - 20px);
        // }

        h3 {
          font-size: 20px;
          line-height: 20px;
          margin: 0 0 5px;
        }

        p {
          font-size: 16px;
          line-height: 20px;

          :nth-child(1) {
            width: 70px;
            display: inline-block;
          }
        }
      }
    }
  }
}
