@import "../../style/theme";

.textsearch--container {
  > button {
    margin-right: 20px;
    width: 45px;
    height: 45px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;

    @include media("<=tablet") {
      margin-right: 10px;
    }

    span {
      height: 100%;
      width: 100%;
      color: #204b68;
    }
    .icon svg {
      height: 32px;
      width: 32px;
    }
  }

  //   .textsearch--remove-search-icon {
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     font-size: 16px;
  //     color: $black;
  //     padding-top: 15px;
  //     cursor: pointer;
  //     &-wrapper {
  //       width: 20px;
  //       height: 20px;
  //       transform: translateY(4px);

  //       span {
  //         height: 100%;
  //         width: 100%;
  //       }
  //     }
  //   }
}

.textsearch--content-container {
  position: fixed;
  top: var(--navbar-height);
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: 2000;
  width: 100%;
  max-width: 1380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $newLightGreen;
  overflow-y: auto;
}

.textsearch--loading-indicator {
  margin-top: 50px;
  font-size: 28px;
}

.textsearch--input-container {
  width: 100%;
  max-width: 600px;
  padding: 25px;

  input {
    width: calc(100% - 40px);
    line-height: 28px;
    background: #aed6bd 0% 0% no-repeat padding-box;
    border: 1px solid #1f645b;
    border-radius: 10px 0 0 10px;
    padding: 10px;
  }

  button {
    width: 45px;
    height: 50px;
    background: #1b645b 0% 0% no-repeat padding-box;
    border: 1px solid #1b645b;
    border-radius: 0 10px 10px 0;
    position: absolute;
    bottom: 5;
    cursor: pointer;

    span {
      height: 100%;
      width: 100%;
      color: #ffffff;
    }
  }
}

.textsearch--search-result-row {
  width: calc(100% - 20px);
  max-width: 600px;
  padding: 25px;
  background: #fff;
  border: 2px solid $newDarkGreen;
  border-radius: 10px;
  margin: 20px 0;
  cursor: pointer;
}

.textsearch--search-result-title {
  color: $newDarkGreen;
  font-size: 28px;
  margin-bottom: 10px;
}

.textsearch--search-result-content {
  color: $text-primary;
  margin-bottom: 10px;
}

.textsearch--search-result-url {
  color: $greyBlue;
  word-wrap: break-word;
}
