@import "../style/theme";

.bookmarks-wrapper {
  .bp3-popover-target {
    width: 100%;

    button {
      box-shadow: none;
      background: $white;
      border: 1px solid $lightBlue;
      border-radius: 2px;
      padding: 15px;

      .bp3-button-text {
        flex: 1;
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
      }
    }
  }
}
