@import "../../style/theme";

.avvik-dropdown--content {
  .avvik-content--legend {
    .legend--symbol {
      display: inline-block;
      @include square(12px);
      border-radius: 50%;
      border: 1px solid lightgray;
      margin-right: 15px;
    }
    .ubehandlet {
      background: $redOrange;
    }
    .underBehandling {
      background: $yellow;
    }
  }
  .btn {
    background: $lightBlue;
    color: $white;
    font-size: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
