@import "~include-media";

@import "./colors";
@import "./typography";
@import "./mixins";
@import "./globals";

@import "~react-adaptive-dt-form/lib/style.scss";

// h2.lightBlue {
//   color: $lightBlue;
// }

// h2.blue {
//   color: $darkGreyBlue;
// }

// h3 {
//   margin: 10px 0 5px 0;
// }

// h4 {
//   margin: 10px 0;
// }

.bp3-button:focus {
  outline-color: currentColor;
}

.bp3-input {
  &:focus {
    outline: rgba($green, 0.6) auto 2px;
    box-shadow: 0 0 0 1px $green, 0 0 0 3px rgba($green, 0.3),
      inset 0 1px 1px rgba(16, 22, 26, 0.2);
  }
}
