@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + ".eot"),
      url($font-file + ".eot?#iefix") format("embedded-opentype"),
      url($font-file + ".woff") format("woff"),
      url($font-file + ".ttf") format("truetype"),
      url($font-file + ".svg#" + $font-family) format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

@include font(
  "Mr Eaves XL Mod OT",
  "../static/assets/fonts/MrEavesXLModOT-Reg"
);
@include font(
  "Mr Eaves XL Mod OT-Bold",
  "../static/assets/fonts/MrEavesXLModOT-Bold"
);
@include font(
  "Mr Eaves XL Mod OT-Book",
  "../static/assets/fonts/MrEavesXLModOT-Book"
);

$font-family-regular: "Mr Eaves XL Mod OT";
$font-family-bold: "Mr Eaves Xl Mod OT-Bold";
$font-family-book: "Mr Eaves XL Mod OT-Book";

$base-font-size: 20px;
$base-line-height: 28px;

*,
html,
body {
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-family: $font-family-regular, serif;
  -wekit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
    font-family: $font-family-bold;
    font-weight: normal;
  }

  h2 {
    font-size: 42px;
    line-height: 44px;
  }

  h3 {
    font-size: 32px;
    line-height: 28px;
  }

  h4 {
    font-size: 20px;
    line-height: 28px;
  }

  p,
  a,
  span,
  li {
    font-family: $font-family-regular;
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
  }
}
