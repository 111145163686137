@import "../style/theme";

.index-view--root {
  .button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 100px 0;
    @include media("<=tablet") {
      flex-direction: column;
      align-items: center;
    }
  }

  .frontpagelinks--loading {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        display: inline-block;
        width: 10px;
        height: 10px;
        animation: pulse 1.5s linear infinite;
        border-radius: 10px;
        background-color: $darkGreen;
        margin-right: 6px;
      }
    
      span:nth-child(2) {
        animation-delay: 100ms;
      }
      span:nth-child(3) {
        animation-delay: 200ms;
      }
      span:nth-child(4) {
        animation-delay: 300ms;
      }
  }
}
